const consentBox = document.getElementById("cookie-consent");
const acceptBtn = document.getElementById("consentButton");
const rejectBtn = document.getElementById("rejectButton");
const cookieOverlay = document.getElementById("overlay-cookie");
const mapHTML =
  '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2654.106384359192!2d16.564443977451422!3d48.300805339782116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d03acf4da6dcb%3A0xbe4f8b83ca2aa536!2ssunflowyoga%20Deutsch-Wagram!5e0!3m2!1sde!2sat!4v1731340453592!5m2!1sde!2sat" class="g-map" width="600" height="400" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

window.toggleMenu = function toggleMenu() {
  const hamburger = document.getElementById("hamburger");
  const menu = document.getElementById("menu");
  const overlay = document.getElementById("overlay");
  const header = document.getElementById("header");

  hamburger.classList.toggle("is-active");
  menu.classList.toggle("open");
  overlay.classList.toggle("show");
  header.classList.toggle("menu-open");
};

function fixHeaderOnScrollPosition() {
  if (window.scrollY > 100) {
    header.classList.add("fixed");
    document.body.classList.add("header-fixed");
  } else {
    header.classList.remove("fixed");
    document.body.classList.remove("header-fixed");
  }
}

function fadeInOnScroll() {
  const fadeInElements = document.querySelectorAll(
    ".fade-in-left, .fade-in-right"
  );
  const windowHeight = window.innerHeight;

  fadeInElements.forEach((element) => {
    const rect = element.getBoundingClientRect();

    if (rect.top <= windowHeight - 100) {
      element.classList.add("visible");
    }
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const header = document.getElementById("header");
  fadeInOnScroll();

  window.addEventListener("scroll", () => {
    fixHeaderOnScrollPosition();
    fadeInOnScroll();
  });

  let cookieConsent = document.cookie.indexOf("cookie-consent=true");
  let cookieRejected = document.cookie.indexOf("cookie-reject=true");
  if (cookieConsent !== -1 || cookieRejected !== -1) {
    toggleCookieConsent(false);
    if (cookieConsent !== -1) {
      const mapContainer = document.getElementById("maps-container");
      if (mapContainer) {
        mapContainer.innerHTML = mapHTML;
      }
    }
    return;
  }
  toggleCookieConsent(true);
});

acceptBtn.onclick = () => {
  document.cookie = "cookie-consent=true; max-age=" + 60 * 60 * 24;
  const mapContainer = document.getElementById("maps-container");

  if (mapContainer) {
    mapContainer.innerHTML = mapHTML;
  }
  toggleCookieConsent(false);
};

rejectBtn.onclick = () => {
  document.cookie = "cookie-reject=true; max-age=" + 60 * 60 * 24;
  toggleCookieConsent(false);
};

toggleCookieConsent = (show) => {
  if (show) {
    consentBox.classList.remove("hide");
    cookieOverlay.classList.add("show");
    document.body.classList.add("cookie-open");
  } else {
    consentBox.classList.add("hide");
    cookieOverlay.classList.remove("show");
    document.body.classList.remove("cookie-open");
  }
};
